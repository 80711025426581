<template>
  <v-row class="pa-10">
    <Overlay :loading="loading" />
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-row no-gutters>
            <span class="text-h4">
              <!-- Olá, {{ currentUser.name || "Não definido" }} -->
              Painel Gerencial
            </span>
          </v-row>
          <v-row no-gutters>
            <span class="caption font-weight-light pt-0 pb-0">
              <!-- {{currentDate}} -->
              Acompanhamentos dos atendimentos do sistema
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-row>
      <!-- Pendências -->
      <v-col cols="12" sm="12" md="12" lg="6">
        <v-col cols="12">
          <v-toolbar
            height="50px"
            color="#BC2E2E"
            class="d-flex justify-center elevation-0"
          >
            <v-toolbar-title class="white--text">
              <span>Pendências</span>
            </v-toolbar-title>
          </v-toolbar>
        </v-col>

        <!-- Fila de espera -->
        <v-col cols="12">
          <v-card
            class="elevation-0"
            outlined
            rounded="0"
            height="59px"
            @click="$router.push({ name: 'ManagerQueue' })"
          >
            <v-row class="fill-heigth">
              <v-col cols="8" class="d-flex">
                <div class="">
                  <h4 class="txt  ml-3 font-weight-thin">Fila de espera</h4>
                </div>
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <h2 class="counterPendences">
                  {{ paginationParams.total }}
                </h2>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>

      <!-- Finalizados -->
      <v-col cols="12" sm="12" md="12" lg="6">
        <v-col cols="12">
          <v-toolbar
            height="50px"
            color="#1c3b7b"
            class="d-flex justify-center elevation-0"
          >
            <v-toolbar-title class="white--text">
              <span>Finalizados</span>
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col cols="12">
          <v-card
            class="elevation-0"
            outlined
            rounded="0"
            height="59px"
            @click="$router.push({ name: 'ManagerFinishedQueue' })"
          >
            <v-row>
              <v-col cols="8" class="d-flex">
                <div class="">
                  <h4 class="txt ml-3 font-weight-thin">
                    Alarmes atendidos
                  </h4>
                </div>
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <div class="d-flex align-center">
                  <h2 class="counter">{{ attendedAlerts.length }}</h2>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            class="elevation-0"
            outlined
            rounded="0"
            height="59px"
            disabled
            @click="$router.push({ name: 'ManagerForwardList' })"
          >
            <v-row>
              <v-col cols="8" class="d-flex">
                <div class="">
                  <h4 class="txt pa-1 ml-3 font-weight-thin">
                    Encaminhamentos para telemedicina
                  </h4>
                </div>
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <div class="d-flex align-center">
                  <!-- <h2 class="counter mb-0">{{ alerts.length }}</h2> -->
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Overlay from "../../../components/Overlay/Overlay.vue";

export default {
  components: {
    Overlay,
  },
  data: () => ({
    paginationParams: {
      page: 1,
      per_page: 10,
      total: 0,
      // sort_by: "id",
      // sort_direction: "asc"
    },
    alerts: [],
    attendedAlerts: [],
    unattendedAlerts: [],
    patient: {},
    loading: false,
  }),
  mounted() {
    this.getAllAlerts();
    this.getUnattendedAlerts();
    this.getAttendedAlerts();
  },
  methods: {
    pagination({ page, itemsPerPage }) {
      itemsPerPage = 0;
      this.paginationParams.per_page = itemsPerPage;
      this.paginationParams.page = page;
      this.getAlerts();
      console.log(this.page);
    },
    formatLastUpdate(date) {
      if (!date) return "";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    async getAllAlerts() {
      try {
        const response = await this.$axios("alerts", {
          params: this.paginationParams,
        });
        this.paginationParams.total = response.data.pagination_params.total;

        this.alerts = response.data;
        console.log("alertasss:" + this.alerts);
      } catch (error) {
        console.log("mensagem de error" + error);
      }
    },
    async getAttendedAlerts() {
      try {
        const response = await this.$axios("attendances");

        this.attendedAlerts = response.data;
        console.log("Alertas atendidos" + this.attendedAlerts);
      } catch (error) {
        console.log("mensagem de error" + error);
      }
    },
    async getUnattendedAlerts() {
      try {
        const response = await this.$axios("alerts", {
          params: {
            unattended: true,
          },
        });
        this.unattendedAlerts = response.data;
        console.log("Não tendidos:" + this.unattendedAlerts);
      } catch (error) {
        console.log("mensagem de error" + error);
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
  },
  watch: {},
};
</script>

<style scopped>
.pendences {
  height: 8vh;
}
.finished {
  height: 8vw;
}
.txt {
  margin-top: 0.8vw;
}
.counter {
  font-size: 4vh;
  font-weight: bold;
  color: #1c3b7b;
}
.counterPendences {
  font-size: 4vh;
  font-weight: bold;
  color: #af0000;
}
</style>
